<template>
  <div>
    <v-container>
      <v-row>
        <v-container>
          <h2>
            ตั้งค่าประเภทเมนู
          </h2>
        </v-container>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="dataTable"
            :items-per-page="10"
            class="elevation-1"
            :search="searchTxt"
          >
            <template v-slot:top>
              <v-row class="mx-2" no-gutters>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="searchTxt"
                    append-icon="mdi-magnify"
                    label="ค้นหา"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-btn color="info" dark class="ma-3" @click="showClick">
                    {{ showTxt }}
                  </v-btn>
                  <v-btn color="primary" dark class="my-3" @click="addLine">
                    เพิ่มรายการ
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.Actions="{ item }">
              <v-icon class="mr-3 primary--text" @click="editLine(item)">
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:item.OnlineStatus="{ item }">
              <v-icon class="info--text" v-if="item.OnlineStatus == 1">
                mdi-check-bold
              </v-icon>
              <v-icon class="secondary--text" v-if="item.OnlineStatus == 0">
                mdi-close-thick
              </v-icon>
            </template>
            <template v-slot:item.Status="{ item }">
              <v-icon class="info--text" v-if="item.Status == 1">
                mdi-check-bold
              </v-icon>
              <v-icon class="secondary--text" v-if="item.Status == 0">
                mdi-close-thick
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <dialogEdit
      :modal="showDialogEdit"
      :item="itemDialogEdit"
      :itemIndex="itemIndexDialogEdit"
      @close="closeDialogEdit"
    ></dialogEdit>
  </div>
</template>

<script>
import dialogEdit from "./DialogSettingMenuType";
import { newMenuType } from "@/store/modules/settings/state";
import { mapActions } from "vuex";
export default {
  components: {
    dialogEdit,
  },
  data() {
    return {
      dataTableAll: [],
      showDialogEdit: false,
      itemDialogEdit: {},
      itemIndexDialogEdit: -1,
      showTxt: "แสดงทั้งหมด",
      searchTxt: "",
      headers: [
        {
          text: "รหัส",
          value: "MenuTypeId",
          divider: true,
          width: 130,
        },
        {
          text: "ชื่อ",
          value: "MenuTypeName",
          divider: true,
        },        
        {
          text: "สั่งออนไลน์",
          value: "OnlineStatus",
          divider: true,
          width: 130,
          align: "center",
        },
        {
          text: "สถานะ",
          value: "Status",
          divider: true,
          width: 130,
          align: "center",
        },
        {
          text: "",
          value: "Actions",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    dataTable: function() {
      if (this.showTxt == "แสดงทั้งหมด") {
        return this.dataTableAll.filter((list) => list.Status == 1);
      } else {
        return this.dataTableAll;
      }
    },
  },
  methods: {
    ...mapActions("settings", ["actMenuTypeGets"]),
    showClick() {
      if (this.showTxt == "แสดงทั้งหมด") {
        this.showTxt = "เฉพาะใช้งาน";
      } else {
        this.showTxt = "แสดงทั้งหมด";
      }
    },
    loadData() {
      this.actMenuTypeGets().then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          this.dataTableAll = data.result;
        } else {
          this.Error(data.message);
        }
      });
    },
    addLine() {
      this.itemDialogEdit = Object.assign({}, newMenuType());
      this.itemIndexDialogEdit = -1;
      this.showDialogEdit = true;
    },
    editLine(item) {
      this.itemDialogEdit = item;
      this.itemIndexDialogEdit = this.dataTable.indexOf(item);
      this.showDialogEdit = true;
    },
    closeDialogEdit(value) {
      this.showDialogEdit = value;
      this.loadData();
    },
  },
};
</script>

<style>
.container{
  max-width: 1500px;
}
</style>
